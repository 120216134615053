import { Link } from "react-router-dom";
import './App.css';

export default function AboutPage() {

  return (
    <div id="about-page" className="app">
      <h1>About</h1>
      <Link to={`/`} className="back">back</Link>
    </div>
  );
}