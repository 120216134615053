import { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import './App.css';

const licenseKey = '5c8752091db66d906c1854cca54655e9cb99NRAL';
const log = {
  "timestamp": Date.now(),
  "service-name": "test-service",
  "user": {
    "id": 123,
    "name": "guga"
  }
};

export default function ContactPage() {

  const sendLog = async () => {
    const res = await axios.post(`https://log-api.newrelic.com/log/v1?Api-Key=${licenseKey}`, log);
    console.log(res);
  }

  useEffect(() => {
    window.newrelic.addPageAction('ContactPageAction', log);
    sendLog()
  }, [])

  return (
    <div id="contact-page" className="app">
      <h1>Contact</h1>
      <Link to={`/`} className="back">back</Link>
    </div>
  );
}